import $ from 'jquery'
import 'slick-carousel'

$(document).ready(initCarouselSlider)
if (window.acf) {
    window.acf.addAction('render_block_preview/type=image_text_carousel', initCarouselSlider)
}

function initCarouselSlider() {
    if ($('.js-img-carousel').length) {
        console.log('initCarouselSlider')
        $('.js-img-carousel').slick({
            arrows: true,
            dots: true,
            fade: true,
            prevArrow: '<button type="button" class="slick-prev"><svg width="59" height="81" viewBox="0 0 59 81" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.65" width="60" height="80" transform="matrix(-1 0 0 1 59 0.948242)" fill="white" /><path d="M34 60.9482L18 40.9482L34 20.9482" stroke="#1D2B5B" /></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg width="59" height="81" viewBox="0 0 59 81" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.65" y="0.948242" width="60" height="80" fill="white" /><path d="M25 60.9482L41 40.9482L25 20.9482" stroke="#1D2B5B" /></svg></button>',
        });
    }
}
