import $ from 'jquery'
const $doc = $(document)

function toggleAccordionItem() {
  event.preventDefault()
  $(this).parent().find('.wysiwyg-editor').slideToggle();
  $(this).toggleClass('minusIcon');
}

function accordionProp(e){
  // Do something
  e.stopPropagation();
}

$('.js-accordion-link').keypress(function (e) {
  var key = e.which;
  if (key == 13){
    event.preventDefault()
    $(this).parent().find('.wysiwyg-editor').slideToggle();
    $(this).toggleClass('minusIcon');
  }
});

$doc.on('click', '.js-accordion-link', toggleAccordionItem)
$doc.on('click', '.js-accordion-link a', accordionProp)
